<template>
  <section class="row m-0 form-home">
    <div class="col-md-10 ext-form mx-auto">
      <div class="contato">
        <ul>
          <li>
            <h4 style="font-size: 20px;" class="mb-2">Proteja quem você Ama!</h4>
            <p>Desde o primeiro contato, estamos comprometidos em entender suas necessidades e oferecer a solução com o
              melhor custo-benefício possível. Além disso, fornecemos condições de pagamento facilitadas, Nós valorizamos
              a transparência e a honestidade em todas as nossas interações com os clientes, e estamos sempre disponíveis
              para ajudar com quaisquer dúvidas ou preocupações que possam surgir ao longo do caminho. Conte conosco para
              fornecer soluções eficazes e acessíveis que atendam às suas necessidades.</p>
            <p>Oferecemos uma avaliação GRATUITA* do local para garantir uma instalação ágil e eficiente, além de fornecer
              suporte completo e um excelente serviço de pós-venda. Nossos consultores especializados estão disponíveis
              agora para ajudá-lo a obter a solução perfeita para suas necessidades. Não hesite em entrar em contato
              conosco para agendar sua avaliação e descobrir como podemos ajudá-lo. Estamos comprometidos em fornecer um
              serviço excepcional a cada cliente, desde o primeiro contato até a conclusão do projeto. Conte conosco para
              um serviço de qualidade e uma experiência satisfatória.</p>
            <p>* Oferecemos orçamentos gratuitos para Campinas e região. Entre em contato com nossos consultores agora
              mesmo para obter um orçamento personalizado!</p>
            <div class="contatos">
              <p><a href="tel:1922232571"><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                    fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                  &nbsp;Campinas/SP</span></a></p>
              <p><a href="tel:1922232571"><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                      fill="currentColor" class="bi bi-telephone-outbound-fill" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                    </svg>&nbsp;(19)&nbsp;2223-2571</span></a></p>
              <p><a
                  href="https://api.whatsapp.com/send?phone=5519992114069&amp;text=Olá%20Campinas%20Redes%20de%20Proteção,%20gostaria%20de%20solicitar%20um%20orçamento."><span><svg
                      xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp"
                      viewBox="0 0 16 16">
                      <path
                        d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                    </svg>&nbsp;(19)&nbsp;9&nbsp;9211-4069</span></a></p>
              <p><a href="mailto:contato@campinasredesdeprotecao.com.br"><span><svg xmlns="http://www.w3.org/2000/svg"
                      width="16" height="16" fill="currentColor" class="bi bi-clock-fill" viewBox="0 0 16 16">
                      <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                    </svg>&nbsp;contato@campinasredesdeprotecao.com.br</span></a></p>
            </div>
          </li>
          <li>
            <div class="formulario">
              <h4 class="txt-normal">Solicite um orçamento</h4>
              <form class="book-taxi-form" method="POST">
                <input placeholder="Nome:" autocomplete="off" type="text" size="30" name="nome">
                <input placeholder="E-mail:" autocomplete="off" type="text" size="30" name="email">
                <input placeholder="Telefone:" autocomplete="off" type="text" size="35" name="telefone">
                <select name="servico">
                  <option value=""> "Qual é a sua preferência de contato?"</option>
                  <option value="Redes de Proteção">Whatsapp</option>
                  <option value="Varais">E-mail</option>
                  <option value="Cerca de piscina">Telefone</option>
                </select>
                <textarea placeholder="Mensagem:" name="mensagem"></textarea>
                <button class="bto-envio" type="submit" name="BTEnvia"><svg xmlns="http://www.w3.org/2000/svg" width="16"
                    height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
                  </svg>&nbsp;Solicite um orçamento gratuito</button>
              </form>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-md-6 float-left">
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
section{
  margin:7rem 0!important;
}
span,
svg {
  vertical-align: middle;
}
.contato {
  ul {
    margin: 0 !important;
    padding: 0 !important;
    display: grid;
    grid-template-columns: repeat(2, calc((100%)/2));

@media only screen and (max-width: 600px) {
  grid-template-columns: repeat(1, 100vw)
}
  }
}
.contatos {
  margin: 2rem 0!important;
  * {
    text-align: left;
    color: #374151
}
p{
  padding:.3rem 0!important;
}
  a ,span{
    width: 100%!important;
  }
}
.formulario{
  margin:2rem 0!important;
  background: #fff;
  border-radius: 25px;
  padding: 2rem 0!important;
  @media only screen and (max-width: 600px) {
    border-radius: 0
  }
}
.formulario input {
  padding: 20px 10px;
  width: 90%!important;
  height: 51px;
  border: solid 1px #EAEAEA;
  border-radius: 10px;
  color: #9B9B9B !important;
  outline: none;
}
input {
  overflow: visible;
  width: 100%;
  margin-bottom: 15px!important;
}
button,
input {
  overflow: visible;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
input[type="text" i] {
  padding: 1px 2px;
}
input {
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: fieldtext;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
  background-color: field;
  margin: 0em;
  padding: 1px 2px;
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
}
.formulario select {
  width: 90%;
  height: 51px;
  margin-bottom: 18px;
  border: solid 1px #EAEAEA;
  border-radius: 10px;
  padding: 4px 7px;
  color: #9B9B9B !important;
  outline: none;
}
select {
  word-wrap: normal;
}
button,
select {
  text-transform: none;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
select:not(:-internal-list-box) {
  overflow: visible !important;
}
select {
  text-rendering: auto;
  color: fieldtext;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  box-sizing: border-box;
  align-items: center;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  background-color: field;
  cursor: default;
  margin: 0em;
  border-width: 1px;
  border-style: solid;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
  border-radius: 0px;
}
.formulario textarea {
  width: 90%;
  min-height: 104px;
  border-radius: 10px;
  border: solid 1px #EAEAEA;
  color: #9B9B9B !important;
  padding: 4px 7px;
  outline: none;
}
textarea {
  overflow: visible;
  width: 100%;
  margin-bottom: 15px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
textarea {
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: fieldtext;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  resize: auto;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  background-color: field;
  column-count: initial !important;
  margin: 0em;
  border-width: 1px;
  border-style: solid;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
  padding: 2px;
}
.bto-envio {
  width:80%;
  max-width: 464px;
  height: 61px;
  background: orange;
  border: 0;
  color: #fff;
  border-radius: 10px;
  outline: none;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 15px;
  margin-top: 12px;
}
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
}
button,
select {
  text-transform: none;
}
button,
input {
  overflow: visible;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button {
  border-radius: 0;
}</style>
